import * as React from "react";
import axios from "axios";
import { AuthContext } from "../../../../../Auth/AuthContext";
import { Constants } from "../../../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../../../utilities/getAxiosRequestConfig";
import { Icon } from "@fluentui/react/lib/Icon";
import { Root } from "./DownloadFileButton.style";
import { useCallback, useContext } from "react";

type GameFile = "replay" | "pull-logs" | "stdout";

const DownloadIcon = () => <Icon iconName="Download" />;

const getFileName = (file: GameFile) => {
    if (file === "replay") {
        return "replay.json";
    } else if (file === "pull-logs") {
        return "pull-logs.log";
    } else {
        return "stdout.log";
    }
};

const download = async (token: string, url: string, filename: string, isJSON: boolean) => {
    const result = await axios.get(url, getAxiosRequestConfig(token));
    const stringData = isJSON ? JSON.stringify(result.data) : result.data;
    const blob = new Blob([stringData]);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    link.download = filename;
    link.click();
};

interface IProps {
    readonly gameId: string;
    readonly file: GameFile;
    readonly label: string;
}

export const DownloadGameFileButton: React.FC<React.PropsWithChildren<IProps>> = ({ gameId, file, label }) => {
    const { token } = useContext(AuthContext);
    const onClick = useCallback(async () => {
        if (token !== null) {
            const url = `${Constants.ApiRoot}/game/${gameId}/${file}`;
            const isJSON = file === "replay";
            await download(token, url, getFileName(file), isJSON);
        }
    }, [token, gameId, file]);
    return (
        <Root onClick={onClick}>
            {label} <DownloadIcon />
        </Root>
    );
};
