import axios, { AxiosError } from "axios";
import { AuthContext } from "../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../utilities/Constants";
import { IGetTeamAdjudicatedGames } from "@coderone/library";

export interface IGetGamesOptions {
    readonly paginationTime: number;
    readonly pageNumber: number;
    readonly agentAlias: string;
}

const getGamesByTeamId = async (token: string | null, teamId: string | undefined, options: IGetGamesOptions) => {
    const params = new URLSearchParams(options as unknown as Record<string, string>);
    const queryString = params.toString();
    if (teamId === undefined) {
        return null;
    }
    const config = getAxiosRequestConfig(token);
    const baseUri = `${Constants.ApiRoot}/team/${teamId}/game`;
    const uri = queryString.length > 0 ? `${baseUri}?${params.toString()}` : baseUri;
    try {
        const { data } = await axios.get<IGetTeamAdjudicatedGames>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

export const useTeamGames = (teamId: string | undefined, options: IGetGamesOptions) => {
    const { token } = useContext(AuthContext);

    return useQuery(["teamGames", teamId, options], () => getGamesByTeamId(token, teamId, options));
};
