import { IPagination } from "@coderone/library";
import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { InfoBar } from "../InfoBar/InfoBar";
import { ButtonContainer } from "./PaginationControl.styles";

interface IProps {
    readonly pagination?: IPagination;
    readonly onNextPressed: () => void;
    readonly onPrevPressed: () => void;
}

export const PaginationControl: React.FC<IProps> = ({ pagination, onNextPressed, onPrevPressed }) => {
    const [t] = useTranslation();
    if (pagination === undefined) {
        return null;
    }
    const { total, currentPage, pageSize } = pagination;
    const totalPages = Math.ceil(total / pageSize);
    const pageIndex = currentPage - 1;
    const resultStart = pageIndex * pageSize + 1;
    const resultEnd = currentPage * pageSize;
    const isPreviousDisabled = currentPage <= 1;
    const isNextDisabled = currentPage >= totalPages;
    if (totalPages <= 1) {
        return null;
    }
    return (
        <>
            <InfoBar>{t("paginationControl.summary", { resultStart, resultEnd, total })}</InfoBar>
            <ButtonContainer>
                <PrimaryButton onClick={onPrevPressed} disabled={isPreviousDisabled}>
                    {t("paginationControl.previousPage")}
                </PrimaryButton>
                <PrimaryButton onClick={onNextPressed} disabled={isNextDisabled}>
                    {t("paginationControl.nextPage")}
                </PrimaryButton>
            </ButtonContainer>
        </>
    );
};
