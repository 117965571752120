import * as React from "react";
import { Root } from "./AdjudicatedGames.styles";
import { GamesList } from "./GamesList/GamesList";

export const AdjudicatedGamesContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Root>
            <GamesList />
        </Root>
    );
};
