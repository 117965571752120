import styled from "styled-components";

export const PlayButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: 0px;
`;

export const PlayIcon = styled.img`
    display: inline;
    vertical-align: middle;
`;
