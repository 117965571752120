import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTeam } from "../../../../../hooks/api/team/useTeam";
import { IGetGamesOptions, useTeamGames } from "../../../../../hooks/api/useTeamGames";
import { getRelativeTime } from "../../../../../utilities/getRelativeTime";
import { ContentCard } from "../../../../ContentCard/ContentCard";
import { InfoBar } from "../../../../InfoBar/InfoBar";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { PaginationControl } from "../../../../PaginationControl/PaginationControl";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { Spinner } from "../../../../Spinner/Spinner";
import { TextInput } from "../../../../TextInput/TextInput";
import { DownloadGameFileButton } from "./DownloadGameFileButton/DownloadGameFileButton";
import { ViewReplayButton } from "./ViewReplayButton/ViewReplayButton";

export const GamesList: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const teamRequest = useTeam();
    const [paginationTime] = useState(new Date().getTime());
    const [currentPage, setCurrentPage] = useState(1);
    const [agentAlias, setAgentAlias] = useState("");
    const options: IGetGamesOptions = { pageNumber: currentPage, paginationTime, agentAlias };
    const gameData = useTeamGames(teamRequest.data?.team._id, options);
    const games = gameData.data?.games;

    const [query, setQuery] = useState("");

    useEffect(() => {
        const timeOutId = setTimeout(() => setAgentAlias(query), 250);
        return () => clearTimeout(timeOutId);
    }, [query]);

    const onQueryChanged = useCallback(
        (_, value: string) => {
            setQuery(value);
        },
        [setQuery]
    );

    const onNextPressed = useCallback(() => {
        setCurrentPage(currentPage + 1);
    }, [currentPage]);
    const onPrevPressed = useCallback(() => {
        setCurrentPage(currentPage - 1);
    }, [currentPage]);

    const input = (
        <TextInput
            label={t("adjudicatedGames.searchByAgentAliasLabel")}
            value={query}
            onChange={onQueryChanged}
            placeholder={t("adjudicatedGames.searchByAgentAliasPlaceholder")}
        />
    );
    const gameList = React.useMemo(() => {
        if (games?.length === 0 || games === undefined) {
            return (
                <InfoBar>
                    <Trans key="adjudicatedGames.noGamesToShow">
                        No match history to show. To join matches, <RouterLink href={CoderOneRoute.Submissions}>submit</RouterLink> an agent
                        and check back.
                    </Trans>
                </InfoBar>
            );
        }
        return (
            <React.Fragment>
                <PaginationControl pagination={gameData.data?.pagination} onNextPressed={onNextPressed} onPrevPressed={onPrevPressed} />

                <table>
                    <thead>
                        <tr>
                            <th>{t("adjudicatedGames.date")}</th>
                            <th>{t("adjudicatedGames.a")}</th>
                            <th>{t("adjudicatedGames.b")}</th>
                            <th>{t("adjudicatedGames.winner")}</th>
                            <th>{t("adjudicatedGames.statusA")}</th>
                            <th>{t("adjudicatedGames.statusB")}</th>
                            <th>{t("adjudicatedGames.replay")}</th>
                            <th>{t("adjudicatedGames.stdout")}</th>
                            <th>{t("adjudicatedGames.watch")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {games.map((game) => {
                            const { completed, _id, winner, statusA, statusB, replay, stdout, nameA, aliasA, nameB, aliasB } = game;
                            const replayButton = replay && (
                                <DownloadGameFileButton gameId={_id} file="replay" label={t("adjudicatedGames.replay")} />
                            );
                            const viewReplayButton = replay && <ViewReplayButton gameId={_id} />;
                            const stdoutButton = stdout && (
                                <DownloadGameFileButton gameId={_id} file="stdout" label={t("adjudicatedGames.stdout")} />
                            );
                            return (
                                <tr key={_id}>
                                    <td>{getRelativeTime(t, completed)}</td>
                                    <td>
                                        {nameA} - {aliasA}
                                    </td>
                                    <td>
                                        {nameB} - {aliasB}
                                    </td>
                                    <td>{winner ?? t("adjudicatedGames.noWinner")}</td>
                                    <td>{statusA}</td>
                                    <td>{statusB}</td>
                                    <td>{replayButton}</td>
                                    <td>{stdoutButton}</td>
                                    <td>{viewReplayButton}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }, [games]);
    if (teamRequest.status === "loading" || gameData.status === "loading") {
        return (
            <React.Fragment>
                {input}
                <Spinner />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {input}
            <ContentCard maxWidth="none">{gameList}</ContentCard>
        </React.Fragment>
    );
};
