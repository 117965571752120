import * as React from "react";
import { CoderOneRoute } from "../../../../../Pages/CoderOneRoute";
import { AuthContext } from "../../../../../Auth/AuthContext";
import { Constants } from "../../../../../../utilities/Constants";
import axios from "axios";
import { getAxiosRequestConfig } from "../../../../../../utilities/getAxiosRequestConfig";
import { telemetry } from "../../../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../../../utilities/Telemetry/TelemetryEvent";
import { useCallback, useContext } from "react";
import { navigate } from "gatsby";
import { PlayButton, PlayIcon } from "./ViewReplayButton.styles";
import PlayArrow from "../../../../../Icons/play_arrow.svg";

const getReplay = async (token: string, url: string) => {
    const result = await axios.get(url, getAxiosRequestConfig(token));
    const stringReplay = JSON.stringify(result.data);
    return stringReplay;
};

interface IProps {
    readonly gameId: string;
}

export const ViewReplayButton: React.FC<React.PropsWithChildren<IProps>> = ({ gameId }) => {
    const { token } = useContext(AuthContext);
    const onClick = useCallback(async () => {
        if (token !== null) {
            await telemetry.Log(TelemetryEvent.ViewReplayButtonClicked, gameId);
            const url = `${Constants.ApiRoot}/game/${gameId}/replay`;
            const stringReplay = await getReplay(token, url);
            navigate(CoderOneRoute.Replays, { state: { replayFile: stringReplay } });
        }
    }, [token, gameId]);

    return (
        <PlayButton onClick={onClick}>
            <PlayIcon src={PlayArrow} title="Replay" />
        </PlayButton>
    );
};
